import { Portlet, PortletContent, PortletHeader } from '@local/ui'
import { Box, Button, Unstable_Grid2 as Grid2, Paper, Stack, Typography } from '@mui/material'
import { useRouter } from 'next/router'

export default function HomePageFamilySite() {
  const router = useRouter()
  const testdata = [
    { title: '크레디트 라인 그룹웨어', url: 'http://gw.ecreditline.co.kr/' },
    { title: '크레디트 라인 급여사이트', url: 'http://www.trust-hr.co.kr/login.php' },
    { title: '크레디트 라인 밴드', url: 'https://band.us/band/51501373' },
    { title: '베네피아 근로자 복지사업', url: 'https://vacation.benepia.co.kr/login/login.do' },
  ]
  return (
    <Stack justifyContent={'center'} pt={2} px={3} minWidth={500}>
      <Portlet sx={{ minHeight: '40vh' }}>
        <PortletHeader>
          <Typography fontWeight={700} fontSize={'15px'} color={'#454545'}>
            Family Site
          </Typography>
        </PortletHeader>
        <PortletContent>
          <Stack
            direction="column"
            alignItems="flex-start"
            justifyContent="flex-start"
            sx={{
              '& li:hover': {
                cursor: 'pointer',
                fontWeight: 'bold',
              },
            }}
            spacing={0.5}
          >
            {testdata.map((el, idx) => (
              <Box
                onClick={() => {
                  router.push(el.url)
                }}
                key={el.title}
                component="li"
              >
                {el.title}
              </Box>
            ))}
          </Stack>
        </PortletContent>
      </Portlet>
    </Stack>
  )
}
