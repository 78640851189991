import { SxProps } from '@mui/material/styles'

export const rootSx: SxProps = {
  '& .MuiGrid2-root': {
    display: 'flex',
    justifyContent: 'center',
  },
  '& .HomePage-banner-bttn': {
    cursor: 'pointer',
    background: 'rgb(255, 255, 255)',
    color: 'rgb(17, 17, 17)',
    width: 230,
    height: 75,
    border: '1px solid rgb(221, 221, 221)',
    borderRadius: '4px 4px 4px 38px',
    transition: '0.5s',
    overflow: 'hidden',
    '&:hover': {
      borderRadius: '4px 38px 4px 4px',
    },
    '& .x-textBox': {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'center',
      height: '100%',
      pl: 2,
      '& .MuiTypography-root': {
        color: '#000',
        fontSize: '0.7rem',
      },
    },
  },
}
