import { routerPush } from '@/lib/urls'
import { Box, Unstable_Grid2 as Grid, Paper, Stack, Typography, alpha } from '@mui/material'
import Image from 'next/image'
import { useState } from 'react'
import { rootSx } from './style'
export default function HomePageShortBtns() {
  const [box1, setBox1] = useState(false)
  const [box2, setBox2] = useState(false)
  const [box3, setBox3] = useState(false)
  const [box4, setBox4] = useState(false)
  return (
    <Grid sx={rootSx} container spacing={2.5}>
      <Grid xs={12} sm={6} md={3}>
        <Paper
          onMouseLeave={() => setBox1(false)}
          onMouseOver={() => setBox1(true)}
          className="HomePage-banner-bttn"
        >
          {box1 ? (
            <Box
              className="x-textBox"
              onClick={() => routerPush('/homepages/homepage-user-mgmt')}
              sx={{
                backgroundColor: '#B9C67E30',
              }}
            >
              <Typography>크레디트 라인의 사우회 회원 대해</Typography>
              <Typography>알아볼 수 있습니다.</Typography>
            </Box>
          ) : (
            <Stack
              direction="row"
              alignItems="center"
              flex={1}
              justifyContent={'space-around'}
              spacing={2}
              sx={{ height: '100%', backgroundColor: (theme) => alpha('#B9C67E', 0.05) }}
            >
              <Typography align="center" variant={'h5'}>
                사원
              </Typography>
              <Image width={50} height={50} src="/images/icon/idea.png" alt="" />
            </Stack>
          )}
        </Paper>
      </Grid>
      <Grid xs={12} sm={6} md={3}>
        <Paper
          onMouseLeave={() => setBox2(false)}
          onMouseOver={() => setBox2(true)}
          className="HomePage-banner-bttn"
        >
          {box2 ? (
            <Box
              onClick={() => routerPush('/homepages/homepage-club')}
              className="x-textBox"
              sx={{
                backgroundColor: '#F0C56730',
              }}
            >
              <Typography>크레디트 라인의 각종 동아리에</Typography>
              <Typography>대해 알아볼 수 있습니다.</Typography>
            </Box>
          ) : (
            <Stack
              direction="row"
              alignItems="center"
              flex={1}
              justifyContent={'space-around'}
              spacing={2}
              sx={{ height: '100%', backgroundColor: (theme) => alpha('#F0C567', 0.05) }}
            >
              <Typography align="center" variant={'h5'}>
                동호회
              </Typography>
              <Image width={50} height={50} src="/images/icon/friend.png" alt="" />
            </Stack>
          )}
        </Paper>
      </Grid>
      <Grid xs={12} sm={6} md={3}>
        <Paper
          onMouseLeave={() => setBox3(false)}
          onMouseOver={() => setBox3(true)}
          className="HomePage-banner-bttn"
        >
          {box3 ? (
            <Box
              onClick={() => routerPush('/homepages/homepage-board')}
              className="x-textBox"
              sx={{
                backgroundColor: '#F19B6830',
              }}
            >
              <Typography>크레디트 라인의 공지사항 채용 정보에</Typography>
              <Typography>대한 정보를 알아볼 수 있습니다.</Typography>
            </Box>
          ) : (
            <Stack
              direction="row"
              alignItems="center"
              flex={1}
              justifyContent={'space-around'}
              spacing={2}
              sx={{ height: '100%', backgroundColor: (theme) => alpha('#F19B68', 0.05) }}
            >
              <Typography align="center" variant={'h5'}>
                게시판
              </Typography>
              <Image width={50} height={50} src="/images/icon/board.png" alt="" />
            </Stack>
          )}
        </Paper>
      </Grid>
      <Grid xs={12} sm={6} md={3}>
        <Paper
          onMouseLeave={() => setBox4(false)}
          onMouseOver={() => setBox4(true)}
          className="HomePage-banner-bttn"
        >
          {box4 ? (
            <Box
              onClick={() => routerPush('/homepages/homepage-cafeteria')}
              className="x-textBox"
              sx={{
                backgroundColor: '#00A79D30',
              }}
            >
              <Typography>크레디트 라인의 각종 복리후생에 대한</Typography>
              <Typography>정보를 알아볼 수 있습니다.</Typography>
            </Box>
          ) : (
            <Stack
              direction="row"
              alignItems="center"
              flex={1}
              justifyContent={'space-around'}
              spacing={2}
              sx={{ height: '100%', backgroundColor: (theme) => alpha('#00A79D', 0.05) }}
            >
              <Typography align="center" variant={'h5'}>
                복리후생
              </Typography>
              <Image width={50} height={50} src="/images/icon/welfare.png" alt="" />
            </Stack>
          )}
        </Paper>
      </Grid>
    </Grid>
  )
}
