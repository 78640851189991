import { useElementLeftTop } from '@local/ui'
import { Box } from '@mui/material'
import { SxProps } from '@mui/material/styles'
import { useCallback, useEffect, useRef, useState } from 'react'
import Slider, { Settings } from 'react-slick'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'

export const rootSx: SxProps = {
  pt: 1,

  '& .x-slider': {
    display: 'flex',
    alignItems: 'center',
    '& .slick-dots': {
      position: 'absolute',
      bottom: 17,
      '& li button:before': {
        color: '#fff',
        opacity: 0.5,
      },
      '& li.slick-active button:before': {
        opacity: 1,
      },
    },
    '& .slick-slide': {
      filter: 'brightness(0.7)',
      transition: '0.5s',
      '&.slick-center': {
        filter: 'brightness(1.5)',
        '& img': { transform: 'scale(1.3)', transition: '0.2s' },
      },
    },
  },
}

//이미지 슬라이더 이미지
const images = [
  { url: '/images/cl-association-slide/cl-association-slide1.jpg' },
  { url: '/images/cl-association-slide/cl-association-slide2.jpg' },
  { url: '/images/cl-association-slide/cl-association-slide3.jpg' },
  { url: '/images/cl-association-slide/cl-association-slide4.jpg' },
]

export default function HomePageImageSlider() {
  const [windowWidth, setWindowWidth] = useState(0)

  // resize 될때만 함수 불러오기
  let timer: ReturnType<typeof setTimeout>

  const timerRef = useRef<number | undefined>(undefined)

  const resizeWindow = useCallback(() => {
    if (timerRef.current !== undefined) {
      clearTimeout(timerRef.current)
    }
    timerRef.current = window.setTimeout(() => {
      setWindowWidth(window.innerWidth)
    }, 500)
  }, [setWindowWidth])

  useEffect(() => {
    setWindowWidth(window.innerWidth)
    window.addEventListener('resize', resizeWindow)
    return () => {
      window.removeEventListener('resize', resizeWindow)
    }
  }, [resizeWindow])

  const centerMode = () => {
    if (typeof window !== 'undefined') {
      if (windowWidth > 1100) {
        return true
      } else {
        return false
      }
    }
  }
  const Settings: Settings = {
    dots: true,
    arrows: true,
    infinite: true,
    autoplay: true,
    speed: 500,
    centerMode: centerMode(),
    slidesToShow: 1,
    slidesToScroll: 1,
    centerPadding: '250px',
    focusOnSelect: true,
  }
  return (
    <Box className="HomePageImageSlider-root" sx={rootSx}>
      <Slider className="x-slider" {...Settings}>
        {images.map((el) => (
          <Box key={el.url}>
            <Slider>
              <Box
                sx={{
                  height: '60vh',
                  px: 4,
                }}
                component="img"
                src={el.url}
              />
            </Slider>
          </Box>
        ))}
      </Slider>
    </Box>
  )
}
