import { Portlet, PortletContent, PortletHeader } from '@local/ui'
import { Box, Button, Unstable_Grid2 as Grid2, Paper, Stack, Typography } from '@mui/material'

export default function HomePageImageSlider() {
  const testdata = [
    { title: '22년 건강보험 연말정산에 따른 4월 급여 정산의건' },
    { title: '사이트 비용 집행관련 지침사항' },
    { title: '별장 공사 안내' },
    { title: '2023년 04월 CL별장신청 사용자 공지의 건' },
    { title: '근로자 휴가지원 사업 관련 공지' },
    { title: '크레디트 라인 해외여행 중단 공지' },
    { title: '연차 및 반차 품위 작성 관련 안내' },
    { title: '성과급 지급 및 우리사주 배당금 지급 공지의 건' },
    { title: '해외여행 관련 투표 안내' },
  ]
  return (
    <Stack justifyContent={'center'} pt={2} px={3} minWidth={500}>
      <Portlet sx={{ minHeight: '40vh' }}>
        <PortletHeader>
          <Typography fontWeight={700} fontSize={'15px'} color={'#454545'}>
            공지사항
          </Typography>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Button>더보기</Button>
          </Stack>
        </PortletHeader>
        <PortletContent>
          <Stack
            direction="column"
            alignItems="flex-start"
            justifyContent="flex-start"
            sx={{
              '& li:hover': {
                cursor: 'pointer',
                fontWeight: 'bold',
              },
            }}
            spacing={0.5}
          >
            {testdata.map((el, idx) => (
              <Box key={el.title} component="li">
                {el.title}
              </Box>
            ))}
          </Stack>
        </PortletContent>
      </Portlet>
    </Stack>
  )
}
