import handleError from '@/lib/handle-error'
import { routerPush } from '@/lib/urls'
import { useApi } from '@/provider'
import { ApiRequestContext } from '@clover/js-api-common'
import { NewUser } from '@local/domain'
import { Box, Button, Divider, Stack, Typography } from '@mui/material'
import { SxProps } from '@mui/material/styles'
import clsx from 'clsx'
import { useCallback, useEffect, useState } from 'react'
import SliderPage from 'react-slick'

export const rootSx: SxProps = {}
interface Props {
  newUserId?: number
}
export default function HomePageNewMemberBox(props: Props) {
  const { newUserId } = props
  const api = useApi()
  const [newUser, setNewUser] = useState<NewUser>()
  const [imgRefresh, setImgRefresh] = useState(0)
  const reload = useCallback(
    async (ctx: ApiRequestContext, newUserId: number) => {
      try {
        const { newUser } = await api.newUser.info({ ctx, newUserId })
        if (ctx.canceled) return
        setNewUser(newUser)
        return true
      } catch (err) {
        handleError(err)
      } finally {
      }
      return false
    },
    [api],
  )
  useEffect(() => {
    if (!newUserId) return
    const ctx = { canceled: false } as ApiRequestContext
    reload(ctx, newUserId).then((result) => {
      if (result) {
        setImgRefresh(Date.now)
      }
    })
    return () => {
      ctx.canceled = true
      ctx.cancel?.()
    }
  }, [reload, newUserId])

  const { title, name, emplAt, substance, attachFiles = [] } = newUser ?? {}

  return (
    <SliderPage key={imgRefresh}>
      <Box>
        <Stack px={20}>
          {attachFiles[0] && (
            <Box
              sx={{ height: 350, flexBasis: 350, minWidth: 350 }}
              component="img"
              src={attachFiles[0].downloadUrl}
              alt=""
            />
          )}
          <Stack spacing={2} flex={1}>
            <Typography sx={{ fontSize: '1.4rem', fontWeight: 600, pt: 4 }} align="center">
              {name}
            </Typography>
            <Typography pt={2} flex={1} align="center" sx={{ fontSize: '1.2rem', fontWeight: 500 }}>
              {title}
            </Typography>
            <Stack direction="row" justifyContent="center" sx={{ height: 20 }}>
              <Box sx={{ height: '50%', width: 40, borderBottom: '1px solid #c0c0c0' }}></Box>
            </Stack>
            <Typography
              sx={{
                em: { fontWeight: 600, fontSize: '0.9rem', fontStyle: 'normal' },
              }}
              align="center"
            >
              <em>입사일 : </em>
              {emplAt}
            </Typography>
            <Button
              sx={{ pb: 7, alignSelf: 'end' }}
              onClick={() => routerPush('/homepages/homepage-empl')}
              color="inherit"
            >
              더보기
            </Button>
          </Stack>
        </Stack>
      </Box>
    </SliderPage>
  )
}
