import handleError from '@/lib/handle-error'
import { useApi } from '@/provider'
import { ApiRequestContext } from '@clover/js-api-common'
import { NewUserSimple, PagerData } from '@local/domain'
import { Box, Button, Stack, Typography } from '@mui/material'
import { SxProps } from '@mui/material/styles'
import { useCallback, useEffect, useRef, useState } from 'react'
import Slider, { Settings } from 'react-slick'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import HomePageNewMemberBox from './HomePageNewMemberBox'
import HomePageNewMemberSmallBox from './HomePageNewMemberSmallBox'
export const rootSx: SxProps = {
  pt: 2,
  pb: 2,
  overflow: 'hidden',
  backgroundColor: '#F0F0F0',
  '& .x-slider': {
    '& .slick-dots': {
      position: 'absolute',
      bottom: 0,
      '& li button:before': {
        opacity: 0.5,
      },
      '& li.slick-active button:before': {
        opacity: 1,
      },
    },
  },
}

type SearchOption = {
  rowsPerPage: number
  pageNumber: number
  title?: string
}
const DEFAULT_SEARCH_OPTIONS = {
  rowsPerPage: 5,
  pageNumber: 0,
}

export default function HomePageImageSlider() {
  // 검색결과 페이지 데이터
  const [memberPagerData, setMemberPagerData] = useState<PagerData<NewUserSimple>>()
  const api = useApi()
  const [searchOption, setSearchOption] = useState<SearchOption>(DEFAULT_SEARCH_OPTIONS)

  // 목록조회
  const reload = useCallback(
    async (ctx: ApiRequestContext, searchOption: SearchOption) => {
      try {
        const { pagerData } = await api.newUser.list({ ...searchOption, ctx })
        if (ctx.canceled) return
        setMemberPagerData(pagerData)
        return true
      } catch (err) {
        handleError(err)
      } finally {
      }
      return false
    },
    [api],
  )
  // 검색 조건이 변경되면 목록 조회
  useEffect(() => {
    const ctx = { canceled: false } as ApiRequestContext
    reload(ctx, searchOption)
    return () => {
      ctx.canceled = true
      ctx.cancel?.()
    }
  }, [searchOption, reload])

  const Settings: Settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToScroll: 1,
    fade: true,
    slidesToShow: 1,
  }
  const {
    totalPages = 0,
    pageNumber = 0,
    offset = 0,
    totalElements = 0,
    elements = [],
  } = memberPagerData || {}

  //윈도우 크기 값 세팅
  const [windowWidth, setWindowWidth] = useState(0)

  // resize 될때만 함수 불러오기
  let timer: ReturnType<typeof setTimeout>

  const timerRef = useRef<number | undefined>(undefined)

  const resizeWindow = useCallback(() => {
    if (timerRef.current !== undefined) {
      clearTimeout(timerRef.current)
    }
    timerRef.current = window.setTimeout(() => {
      setWindowWidth(window.innerWidth)
    }, 500)
  }, [setWindowWidth])

  useEffect(() => {
    setWindowWidth(window.innerWidth)
    window.addEventListener('resize', resizeWindow)
    return () => {
      window.removeEventListener('resize', resizeWindow)
    }
  }, [resizeWindow])
  return (
    <Box className="HomePageImageSlider-root" sx={rootSx}>
      {windowWidth < 1100 ? (
        <Box>
          <Slider className="x-slider" {...Settings}>
            {elements.map((el) => (
              <HomePageNewMemberSmallBox key={el.createUserId} newUserId={el.newUserId} />
            ))}
          </Slider>
        </Box>
      ) : (
        <Box
          sx={{
            '& .slick-dots': {
              left: 180,
            },
          }}
        >
          <Slider className="x-slider" {...Settings}>
            {elements.map((el) => (
              <HomePageNewMemberBox key={el.createUserId} newUserId={el.newUserId} />
            ))}
          </Slider>
        </Box>
      )}
    </Box>
  )
}
