import { Box, alpha, Unstable_Grid2 as Grid2 } from '@mui/material'
import HomePageShortBtns from './components/HomePageShortBtns/HomePageShortBtns'
import HomePageImageSlider from './components/HomePageImageSlider'
import HomePageNewMemberSlider from './components/HomePageNewMemberSlider'
import HomePageMiniNotice from './components/HomePageMiniNotice'
import HomePageFamilySite from './components/HomePageFamilySite'
import { rootSx } from './style'

export default function HomePageCLMain() {
  return (
    <Box sx={rootSx} className="HomePageCLMain-root">
      <Box
        sx={{
          overflow: 'hidden',
        }}
      >
        <HomePageImageSlider />
      </Box>

      <Box
        sx={{
          backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.04),
          py: 3,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-around',
        }}
      >
        <HomePageShortBtns />
      </Box>
      <HomePageNewMemberSlider />
      <Grid2 container>
        <Grid2 xs={12} md={6}>
          <HomePageMiniNotice />
        </Grid2>
        <Grid2 xs={12} md={6}>
          <HomePageFamilySite />
        </Grid2>
      </Grid2>
    </Box>
  )
}
